<template>
  <LoadingState v-if="isLoading" />
  <div v-else>
    <a-row>
      <a-col :span="24">
        <h1>Assignment Center</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-slots">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item
              v-for="(slot, index) in menuSlots"
              :key="index"
              @click="
                showOverview(
                  {
                    idMapel: slot['id_mata_pelajaran'],
                    mapel: slot['mata_pelajaran.nama'],
                  },
                  {
                    idLevel: slot['kela.id_level'],
                    level: slot['kela.level.nama'],
                  }
                )
              "
              >{{ slot["kela.level.nama"] }} |
              {{ slot["mata_pelajaran.nama"].toUpperCase() }}</a-menu-item
            >
          </a-menu>
          <LoadingState v-if="isLoadingMenu" />
          <template v-else>
            <div class="detail-slot mt-4">
              <a-row type="flex" justify="space-between">
                <a-col :xs="12" :lg="6">
                  <h3 class="type">Subject</h3>
                  <p class="value">{{ mapel || "-" }}</p>
                </a-col>
                <a-col :xs="12" :lg="6">
                  <h3 class="type">Level</h3>
                  <p class="value">{{ overviewLevel }}</p>
                </a-col>
                <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
                  <h3 class="type">Week Progress</h3>
                  <p class="value" v-if="overviewAssignment.total_week">
                    <span>{{ overviewAssignment.current_week || "-" }}</span>
                    / {{ overviewAssignment.total_week }} Week
                  </p>
                  <p class="value" v-else>-</p>
                </a-col>
                <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
                  <h3 class="type">Assignment in Progress</h3>
                  <p class="value">
                    {{ overviewAssignment.total_assignment || "-" }}
                    Assignment(s)
                  </p>
                </a-col>
              </a-row>
            </div>
            <div class="divider">
              <a-divider orientation="left">
                <h3>Assignment in Progress</h3>
              </a-divider>
            </div>
            <a-row v-if="!overviewAips.length" class="my-5">
              <EmptyState
                :heading="EmptyStateAIP.heading"
                :description="EmptyStateAIP.description"
              />
            </a-row>
            <a-row
              :gutter="[32, 32]"
              class="progress-card"
              v-else
            >
              <div v-for="(item, index) in overviewAips" :key="index">
                <a-col :sm="24" :md="12" :lg="8">
                  <CardProgress :item="item" />
                </a-col>
              </div>
            </a-row>
            <!-- <div class="mx-4">
            <VueSlickCarousel v-bind="settings">
                <CardProgress class="mx-2 my-2" v-for="(item, index) in overviewAips" :key="index" :item="item" />
                <CardProgress class="mx-2 my-2" v-for="(item, index) in overviewAips" :key="index" :item="item" />
                <CardProgress class="mx-2 my-2" v-for="(item, index) in overviewAips" :key="index" :item="item" />
                <CardProgress class="mx-2 my-2" v-for="(item, index) in overviewAips" :key="index" :item="item" />
            </VueSlickCarousel>
          </div> -->
            <!-- <a-row :gutter="[32, 32]" class="progress-card" v-else>
            <VueSlickCarousel v-bind="settings">
              <a-col v-for="(item, index) in overviewAips" :key="index" :sm="24" :md="12" :lg="8">
                <CardProgress :item="item" />
              </a-col>
              <a-col v-for="(item, index) in overviewAips" :key="index" :sm="24" :md="12" :lg="8">
                <CardProgress :item="item" />
              </a-col>
              <a-col v-for="(item, index) in overviewAips" :key="index" :sm="24" :md="12" :lg="8">
                <CardProgress :item="item" />
              </a-col>
              <a-col v-for="(item, index) in overviewAips" :key="index" :sm="24" :md="12" :lg="8">
                <CardProgress :item="item" />
              </a-col>
            </VueSlickCarousel>
          </a-row> -->
            <div class="divider">
              <a-divider orientation="left">
                <h3>Summary of Assignment & Daily Attendance</h3>
              </a-divider>
            </div>
            <div class="table-presences">
              <a-table
                class="table-scroll"
                :columns="columns"
                :data-source="dataOfTableAP"
                :pagination="{ pageSize: 30, hideOnSinglePage: true }"
                bordered
              >
                <div slot="Class" slot-scope="text, record">
                  <router-link
                    :to="{
                      name: 'Presences Assignments Teacher',
                      params: { id: record.id, idMapel: record.idMapel },
                    }"
                  >
                    {{ text }}
                  </router-link>
                </div>
                <span slot="ClassPresences" slot-scope="text">
                  <div v-if="text >= 70 && text <= 100">
                    <a-tag color="green">{{ text }} %</a-tag>
                  </div>
                  <div v-else-if="text >= 35 && text <= 69">
                    <a-tag color="orange">{{ text }} %</a-tag>
                  </div>
                  <div v-else-if="text >= 0 && text <= 34">
                    <a-tag color="red">{{ text }} %</a-tag>
                  </div>
                </span>

                <span slot="AvgGrade" slot-scope="text">
                  <div v-if="text >= 70 && text <= 100">
                    <a-tag color="green">{{ text }}</a-tag>
                  </div>
                  <div v-else-if="text >= 35 && text <= 69">
                    <a-tag color="orange">{{ text }}</a-tag>
                  </div>
                  <div v-else-if="text >= 0 && text <= 34">
                    <a-tag color="red">{{ text }}</a-tag>
                  </div>
                </span>
                <span slot="Graded" slot-scope="text">
                  <div v-if="text >= 70 && text <= 100">
                    <a-tag color="green">{{ text }} %</a-tag>
                  </div>
                  <div v-else-if="text >= 35 && text <= 69">
                    <a-tag color="orange">{{ text }} %</a-tag>
                  </div>
                  <div v-else-if="text >= 0 && text <= 34">
                    <a-tag color="red">{{ text }} %</a-tag>
                  </div>
                </span>
              </a-table>
            </div>
          </template>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>

const CardProgress = () => import('@/components/app/Assignment/CardAssignment')
const EmptyState = () => import('@/components/app/EmptyState')
const LoadingState = () => import('@/components/app/LoadingState')
// import VueSlickCarousel from 'vue-slick-carousel'

const EmptyStateAIP = {
  heading: 'No Data',
  description: 'You have no assignment in progress',
}
export default {
  name: 'OverviewAssignment',
  data() {
    return {
      current: [0],
      EmptyStateAIP,
      overviewAips: [],
      menuSlots: [],
      overviewAssignment: [],
      dataOfTableAP: [],
      mapel: null,
      tingkat: null,
      isLoadingMenu: false,
    }
  },
  components: {
    LoadingState,
    CardProgress,
    EmptyState,
    // VueSlickCarousel,
  },
  methods: {
    toDetailPresences(id, idMapel) {
      this.$router.push({ name: 'Presences Assignments Teacher', params: { id, idMapel } })
    },
    async fetchOverviewAssignment({ idMapel, idLevel }) {
      try {
        this.dataOfTableAP = []
        const { overview, data } = await this.$store.dispatch('slot/FETCH_OVERVIEW_ASSIGNMENT_TEACHER', { idMapel, idLevel })
        this.overviewAssignment = overview
        this.dataOfTableAP = data.map(kelas => {
          return {
            ...kelas,
            key: kelas.id,
          }
        })
        this.isLoadingMenu = false
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchAIP({ idMapel, idLevel }) {
      try {
        const dataAip = await this.$store.dispatch('slot/FETCH_OVERVIEW_AIP_TEACHER', { idMapel, idLevel })
        this.overviewAips = dataAip.map(aip => {
          return {
            ...aip,
          }
        })
        this.isLoadingMenu = false
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async showOverview({ idMapel, mapel }, { idLevel, level }) {
      try {
        this.isLoadingMenu = true
        this.mapel = mapel
        this.tingkat = level
        this.fetchOverviewAssignment({ idMapel, idLevel })
        this.fetchAIP({ idMapel, idLevel })
      } catch (err) {
        this.$notification.error({
          message: 'FAILED',
          description: 'There are some several errors. Please contact admin.',
        })
        console.log(err)
      }
    },
  },
  async created() {
    try {
      this.menuSlots = await this.$store.dispatch('slot/FETCH_MENU_SLOT_TEACHER')
      const currentSlot = this.menuSlots[0]
      this.showOverview({ idMapel: currentSlot.id_mata_pelajaran, mapel: currentSlot['mata_pelajaran.nama'] }, { idLevel: currentSlot['kela.id_level'], level: currentSlot['kela.level.nama'] })
    } catch (err) {
      this.$notification.error({
        message: 'FAILED',
        description: 'There are some several errors. Please contact admin.',
      })
      console.log(err)
    }
    // this.$store.dispatch('slot/FETCH_ASSIGNMENT_GRADING', { idGuru: this.user.id, idMataPelajaran: 1, idKelas: 1 })
  },
  computed: {
    // sesiGradings() {
    //   return this.$store.state.slot.sesiGradings
    // },
    isLoading() {
      return this.$store.state.isLoading
    },
    user() {
      return this.$store.state.user.user
    },
    overviewLevel() {
      let str = `Level ${this.tingkat}`
      if (this.overviewAssignment.total_class) {
        const classTotal = this.dataOfTableAP.length
        str += ` ( ${classTotal}`
        if (classTotal <= 1) {
          str += ' Class )'
        } else {
          str += ' Classes )'
        }
      }
      return str
    },
    settings() {
      const settings = {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
      }

      return settings
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    columns() {
      return [
        {
          title: 'Class',
          dataIndex: 'className',
          key: 'className',
          // width: '25%',
          // width: 200,
          align: 'center',
          scopedSlots: { customRender: 'Class' },
        },
        {
          title: 'Students',
          key: 'totalStudent',
          dataIndex: 'totalStudent',
          // width: '10%',
          // width: 200,
          align: 'center',
          scopedSlots: { customRender: 'Students' },
        },
        {
          title: this.institusi.presence_setting === 'by session' ? 'Session Attendance (%)' : 'Daily Attendance (%)',
          key: 'classPresences',
          dataIndex: 'classPresences',
          align: 'center',
          // width: '12%',
          // width: 200,
          scopedSlots: { customRender: 'ClassPresences' },
        },
        {
          title: 'Finished Assignment',
          children: [
            {
              title: 'Amount',
              dataIndex: 'amountFinished',
              key: 'amountFinished',
              align: 'center',
              // width: 100,
              // width: 200,
              scopedSlots: { customRender: 'AmountFinished' },
            },
            {
              title: 'Avg. Grade',
              dataIndex: 'avgGradeOnFinished',
              key: 'avgGradeOnFinished',
              align: 'center',
              // width: 80,
              // width: 200,
              scopedSlots: { customRender: 'AvgGrade' },
            },
          ],
        },
        {
          title: 'Assignments in Progress',
          children: [
            {
              title: 'Amount',
              dataIndex: 'amountProgress',
              key: 'amountProgress',
              align: 'center',
              // width: 100,
              // width: 200,
              scopedSlots: { customRender: 'AmountProgress' },
            },
            {
              title: 'Submitted',
              dataIndex: 'inProgressSubmitted',
              key: 'inProgressSubmitted',
              align: 'center',
              // width: 100,
              // width: 200,
              scopedSlots: { customRender: 'Submitted' },
            },
            {
              title: 'Graded (%)',
              dataIndex: 'gradedOnInProgress',
              key: 'gradedOnInProgress',
              align: 'center',
              // width: 100,
              // width: 200,
              scopedSlots: { customRender: 'Graded' },
            },
          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.card-slots {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail-slot {
  padding: 40px 20px;
}

.detail-slot .type {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}

.detail-slot .value {
  padding-top: 5px;
  font-family: "Mukta", "sans-serif";
  font-size: 16px;
}

.detail-slot .value span {
  color: #1b90ff;
}

.progress-card {
  padding: 10px 20px;
}

.table-presences {
  padding: 10px 20px 20px 20px;
}

.table-scroll {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
  }
}
</style>
